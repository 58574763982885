import React from 'react';

import Loadable from 'react-loadable';

import SiteConfiguration from './views/HardPhoneManagement/SiteConfiguration';
import { PaymentSettings } from './views/PaymentSettings';

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const ListSubscription = Loadable({
  loader: () =>
    import('./views/Subscription/ListSubscription/ListSubscription.js'),
  loading: Loading,
});
const ListTrials = Loadable({
  loader: () => import('./views/Subscription/ListTrials/ListTrials.js'),
  loading: Loading,
});

const AccountDetails = Loadable({
  loader: () => import('./views/Subscription/Details/AccountDetails'),
  loading: Loading,
});

const NewSubsToday = Loadable({
  loader: () => import('./views/DashboardLists/NewSubsToday'),
  loading: Loading,
});

const NewSubsYesterday = Loadable({
  loader: () => import('./views/DashboardLists/NewSubsYesterday'),
  loading: Loading,
});

const ConvertedSubsToday = Loadable({
  loader: () =>
    import('./views/DashboardLists/ConvertedSubsToday/ConvertedSubsToday'),
  loading: Loading,
});

const ConvertedSubsThisWeek = Loadable({
  loader: () =>
    import(
      './views/DashboardLists/ConvertedSubsThisWeek/ConvertedSubsThisWeek'
    ),
  loading: Loading,
});

const MobileApp = Loadable({
  loader: () => import('./views/MobileApp/MobileApp'),
  loading: Loading,
});

const Billing = Loadable({
  loader: () => import('./views/Subscription/Billing/Billing'),
  loading: Loading,
});

const LocationsUser = Loadable({
  loader: () => import('./views/Locations/LocationsUser'),
  loading: Loading,
});

const LocationsAlr = Loadable({
  loader: () => import('./views/LocationsAlr'),
  loading: Loading,
});

const CallHistoryReport = Loadable({
  loader: () => import('./views/Reports/CallHistoryReport'),
  loading: Loading,
});

const MatchBackReport = Loadable({
  loader: () => import('./views/Reports/MatchBackReport'),
  loading: Loading,
});

const MatchBackLocationReport = Loadable({
  loader: () => import('./views/Reports/MatchBackLocationReport'),
  loading: Loading,
});

const VoiceMailReport = Loadable({
  loader: () => import('./views/Reports/VoiceMailReport'),
  loading: Loading,
});

const GymChatReport = Loadable({
  loader: () => import('./views/Reports/GymChatReport'),
  loading: Loading,
});

const GymLeadReport = Loadable({
  loader: () => import('./views/Reports/GymLeadReport'),
  loading: Loading,
});

const GymLeadStatsReport = Loadable({
  loader: () => import('./views/Reports/GymLeadStatsReport'),
  loading: Loading,
});

const SubscriptionLocationDetails = Loadable({
  loader: () => import('./views/LocationDetails/SubscriptionLocationDetails'),
  loading: Loading,
});

const GymAnswersReport = Loadable({
  loader: () => import('./views/Reports/GymAnswersReport'),
  loading: Loading,
});

const createSubscription = Loadable({
  loader: () => import('./views/Subscription/Components/Create'),
  loading: Loading,
});

const Overview = Loadable({
  loader: () => import('./views/HardPhoneManagement/Overview'),
  loading: Loading,
});

const SIPDeviceList = Loadable({
  loader: () => import('./views/HardPhoneManagement/SIPDeviceList'),
  loading: Loading,
});

const FirmwareManagement = Loadable({
  loader: () => import('./views/HardPhoneManagement/FirmwareManagement'),
  loading: Loading,
});

const ListSites = Loadable({
  loader: () => import('./views/HardPhoneManagement/Site/List/index'),
  loading: Loading,
});

const CreateSite = Loadable({
  loader: () => import('./views/HardPhoneManagement/Site/Create/index'),
  loading: Loading,
});

const UpdateSite = Loadable({
  loader: () => import('./views/HardPhoneManagement/Site/Update/index'),
  loading: Loading,
});

const GymVidDetails = Loadable({
  loader: () => import('./views/Reports/GymVidDetailsReport'),
  loading: Loading,
});

const GymVidReport = Loadable({
  loader: () => import('./views/Reports/GymVidReport'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./views/Users/BackofficeUsers'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  {
    path: '/dashbrd/new_subs_today',
    name: 'New Subs Today',
    component: NewSubsToday,
  },
  {
    path: '/dashbrd/new_subs_yesterday',
    name: 'New Subs Yesterday',
    component: NewSubsYesterday,
  },
  {
    path: '/dashbrd/converted_subs_today',
    name: 'Converted Subs Today',
    component: ConvertedSubsToday,
  },
  {
    path: '/dashbrd/converted_subs_this_week',
    name: 'Converted Subs This Week',
    component: ConvertedSubsThisWeek,
  },
  { path: '/mobile-app', name: 'Mobile App', component: MobileApp },

  { path: '/subscription/billing', name: 'billing', component: Billing },

  {
    path: '/reports/call-history/',
    name: 'Call History - All Accounts',
    component: CallHistoryReport,
    role: 'personnel',
  },
  {
    path: '/reports/match-back/',
    name: 'Match Back Report',
    role: 'personnel',
    exact: true,
    component: MatchBackReport,
  },
  {
    path: '/reports/match-back/:locationId',
    name: 'Match Back Location Report',
    exact: true,
    role: 'personnel',
    component: MatchBackLocationReport,
  },
  {
    path: '/reports/voice-mail/',
    name: 'Voicemail - All Accounts',
    component: VoiceMailReport,
    role: 'personnel',
  },
  {
    path: '/reports/gym-chat/',
    name: 'GymChat - All Accounts',
    component: GymChatReport,
    role: 'personnel',
  },

  {
    path: '/reports/gym-lead/',
    name: 'GymLead - All Accounts',
    component: GymLeadReport,
    role: 'personnel',
  },
  {
    path: '/reports/gym-lead-stats/',
    name: 'GymLead - All Accounts',
    component: GymLeadStatsReport,
    role: 'personnel',
  },

  {
    path: '/reports/gym-answer/',
    name: 'GymAnswering - All Accounts',
    component: GymAnswersReport,
    role: 'personnel',
  },
  {
    path: '/reports/gym-vid/',
    name: 'GymVid - All Accounts',
    component: GymVidReport,
    role: 'personnel',
  },
  {
    path: '/gym-vid/gym-vid-details',
    name: 'GymVid - Pending approval',
    component: GymVidDetails,
    role: 'personnel',
  },

  {
    path: '/subscription/list',
    name: 'Account Owners List',
    exact: true,
    component: ListSubscription,
  },
  {
    path: '/subscription/trials',
    name: 'Trial Account Owner List',
    exact: true,
    component: ListTrials,
  },

  {
    path: '/subscription/list/create',
    name: 'Create Subscription',
    component: createSubscription,
  },

  {
    path: '/subscription/list/details/:id/:locationId',
    name: 'Location Details',
    exact: true,
    component: SubscriptionLocationDetails,
  },

  {
    path: '/subscription/list/details/:id/',
    name: 'Account Details',
    exact: true,
    component: AccountDetails,
  },

  {
    path: '/subscription/list/details/:id/:locationId/call-history/:helloGymId',
    name: 'Call History',
    exact: true,
    component: CallHistoryReport,
  },
  {
    path: '/subscription/list/details/:id/:locationId/voice-mail/:helloGymId',
    name: 'Voicemail',
    exact: true,
    component: VoiceMailReport,
  },
  {
    path: '/subscription/list/details/:id/:locationId/gym-chat/:helloGymId',
    name: 'GymChat',
    exact: true,
    component: GymChatReport,
  },
  {
    path: '/subscription/list/details/:id/:locationId/gym-lead/:helloGymId',
    name: 'GymLead',
    exact: true,
    component: GymLeadReport,
  },
  {
    path: '/subscription/list/details/:id/:locationId/gym-answer/:helloGymId',
    name: 'GymAnswering',
    exact: true,
    component: GymAnswersReport,
  },
  {
    path: '/subscription/list/details/:id/:locationId/gym-vid/:helloGymId',
    name: 'GymVid',
    exact: true,
    component: GymVidReport,
  },

  // { path: '/subscription/logs', name: 'Logs', component: LogsSubscriber },

  {
    path: '/phone-management/overview',
    name: 'Hard Phone Management - Overview',
    component: Overview,
  },
  {
    path: '/phone-management/site-management',
    name: 'Hard Phone Management - Site Management',
    component: ListSites,
  },
  {
    path: '/phone-management/sip-list',
    name: 'Hard Phone Management - SIP Device List',
    component: SIPDeviceList,
  },
  {
    path: '/phone-management/firmware-management',
    name: 'Hard Phone Management - Firmware Management',
    component: FirmwareManagement,
  },
  {
    path: '/phone-management/site-configuration',
    name: 'Hard Phone Management - Site Configuration',
    component: SiteConfiguration,
  },

  {
    path: '/phone-management/site/create',
    name: 'Hard Phone Management - Create Site',
    component: CreateSite,
  },
  {
    path: '/phone-management/site/update/:siteId',
    name: 'Hard Phone Management - Update Site',
    exact: true,
    component: UpdateSite,
  },

  { path: '/locations/list', name: 'Locations', component: LocationsUser },
  { path: '/locations-alr/list', name: 'Locations', component: LocationsAlr },
  { path: '/users/list', name: 'Users', component: Users },
  {
    path: '/payment-settings',
    name: 'Payment Settings',
    component: PaymentSettings,
  },
];

export const routePathsInV6 = [
  '/subscription/list/details/:id/:locationId',
  '/reports/match-back/:locationId',
];

export default routes;
