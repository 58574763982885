import React, { Component } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { ToastContainer } from 'react-toastify';

import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css';
// Import Toastify styles
import 'react-toastify/dist/ReactToastify.css';

// Containers
import { DefaultLayout } from './containers';
import { Login, Page404, Page500, ResetPassword } from './views/Pages';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnMount: false, refetchOnWindowFocus: false },
  },
});

class App extends Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <HashRouter>
          <CompatRouter>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route
                exact
                path="/password-reset/:email/:passwordResetToken"
                name="Reset Password Page"
                component={ResetPassword}
              />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <Route path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </CompatRouter>
        </HashRouter>
      </QueryClientProvider>
    );
  }
}

export default App;
