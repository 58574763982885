import { EndpointUrlFactory } from '../api-config.js';

let BASE_URL = EndpointUrlFactory('identity') + '/api';

export async function PostLoginBackofficeApi(model) {

    let respose = await fetch(BASE_URL + '/login-backoffice', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(model)
     }).then(res => res.json());

     return respose;
}

export async function ApiLoginUserAccountAsync(username) {
  const URL = `${BASE_URL}/login/login-user-account?username=${encodeURIComponent(username)}`;
  const response = await fetch(URL,
    {
      method: 'GET',
    }
  );
  return response;
}
