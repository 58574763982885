/**
 * VimCorPro Identity API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: felipegouveiae@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import VimCorProIdentityDomainValueObjectGrantedLocations from './VimCorProIdentityDomainValueObjectGrantedLocations';

/**
* The VimCorProIdentityDomainEntitiesISubscriptionContact model module.
* @module model/VimCorProIdentityDomainEntitiesISubscriptionContact
* @version v1
*/
export default class VimCorProIdentityDomainEntitiesISubscriptionContact {
    /**
    * Constructs a new <code>VimCorProIdentityDomainEntitiesISubscriptionContact</code>.
    * @alias module:model/VimCorProIdentityDomainEntitiesISubscriptionContact
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>VimCorProIdentityDomainEntitiesISubscriptionContact</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/VimCorProIdentityDomainEntitiesISubscriptionContact} obj Optional instance to populate.
    * @return {module:model/VimCorProIdentityDomainEntitiesISubscriptionContact} The populated <code>VimCorProIdentityDomainEntitiesISubscriptionContact</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VimCorProIdentityDomainEntitiesISubscriptionContact();
                        
            
            if (data.hasOwnProperty('subscriptions')) {
                obj['subscriptions'] = ApiClient.convertToType(data['subscriptions'], [VimCorProIdentityDomainValueObjectGrantedLocations]);
            }
            if (data.hasOwnProperty('firstName')) {
                obj['firstName'] = ApiClient.convertToType(data['firstName'], 'String');
            }
            if (data.hasOwnProperty('lastName')) {
                obj['lastName'] = ApiClient.convertToType(data['lastName'], 'String');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'String');
            }
            if (data.hasOwnProperty('roles')) {
                obj['roles'] = ApiClient.convertToType(data['roles'], ['String']);
            }
            if (data.hasOwnProperty('phone')) {
                obj['phone'] = ApiClient.convertToType(data['phone'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Array.<module:model/VimCorProIdentityDomainValueObjectGrantedLocations>} subscriptions
    */
    'subscriptions' = undefined;
    /**
    * @member {String} firstName
    */
    'firstName' = undefined;
    /**
    * @member {String} lastName
    */
    'lastName' = undefined;
    /**
    * @member {String} position
    */
    'position' = undefined;
    /**
    * @member {Array.<String>} roles
    */
    'roles' = undefined;
    /**
    * @member {String} phone
    */
    'phone' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;




}
