/**
 * VimCorPro Identity API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: felipegouveiae@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import VimCorProDomainCoreEnumsUserStatus from './VimCorProDomainCoreEnumsUserStatus';

/**
* The VimCorProIdentityDomainEntitiesIUser model module.
* @module model/VimCorProIdentityDomainEntitiesIUser
* @version v1
*/
export default class VimCorProIdentityDomainEntitiesIUser {
    /**
    * Constructs a new <code>VimCorProIdentityDomainEntitiesIUser</code>.
    * @alias module:model/VimCorProIdentityDomainEntitiesIUser
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>VimCorProIdentityDomainEntitiesIUser</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/VimCorProIdentityDomainEntitiesIUser} obj Optional instance to populate.
    * @return {module:model/VimCorProIdentityDomainEntitiesIUser} The populated <code>VimCorProIdentityDomainEntitiesIUser</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VimCorProIdentityDomainEntitiesIUser();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'String');
            }
            if (data.hasOwnProperty('createdDate')) {
                obj['createdDate'] = ApiClient.convertToType(data['createdDate'], 'Date');
            }
            if (data.hasOwnProperty('fullname')) {
                obj['fullname'] = ApiClient.convertToType(data['fullname'], 'String');
            }
            if (data.hasOwnProperty('firstname')) {
                obj['firstname'] = ApiClient.convertToType(data['firstname'], 'String');
            }
            if (data.hasOwnProperty('lastname')) {
                obj['lastname'] = ApiClient.convertToType(data['lastname'], 'String');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('pinCodeHash')) {
                obj['pinCodeHash'] = ApiClient.convertToType(data['pinCodeHash'], 'String');
            }
            if (data.hasOwnProperty('voicemailPinCodeHash')) {
                obj['voicemailPinCodeHash'] = ApiClient.convertToType(data['voicemailPinCodeHash'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = VimCorProDomainCoreEnumsUserStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('locations')) {
                obj['locations'] = ApiClient.convertToType(data['locations'], ['String']);
            }
            if (data.hasOwnProperty('lockoutEnd')) {
                obj['lockoutEnd'] = ApiClient.convertToType(data['lockoutEnd'], 'Date');
            }
            if (data.hasOwnProperty('lockoutEnabled')) {
                obj['lockoutEnabled'] = ApiClient.convertToType(data['lockoutEnabled'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {String} id
    */
    'id' = undefined;
    /**
    * @member {String} companyId
    */
    'companyId' = undefined;
    /**
    * @member {Date} createdDate
    */
    'createdDate' = undefined;
    /**
    * @member {String} fullname
    */
    'fullname' = undefined;
    /**
    * @member {String} firstname
    */
    'firstname' = undefined;
    /**
    * @member {String} lastname
    */
    'lastname' = undefined;
    /**
    * @member {String} position
    */
    'position' = undefined;
    /**
    * @member {String} phoneNumber
    */
    'phoneNumber' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;
    /**
    * @member {String} pinCodeHash
    */
    'pinCodeHash' = undefined;
    /**
    * @member {String} voicemailPinCodeHash
    */
    'voicemailPinCodeHash' = undefined;
    /**
    * @member {module:model/VimCorProDomainCoreEnumsUserStatus} status
    */
    'status' = undefined;
    /**
    * @member {Array.<String>} locations
    */
    'locations' = undefined;
    /**
    * @member {Date} lockoutEnd
    */
    'lockoutEnd' = undefined;
    /**
    * @member {Boolean} lockoutEnabled
    */
    'lockoutEnabled' = undefined;




}
