import React, { Component } from 'react';

import moment from 'moment';

class DateFormatMask extends Component {
  render() {
    const value = this.props.cell || this.props.value;
    return (
      <span>
        {value && moment(value).get('year') > 1800
          ? moment(value).fromNow()
          : '-'}
      </span>
    );
  }
}

export { DateFormatMask };
