import { EndpointUrlFactory } from '../api-config.js';

const BASE_URL = EndpointUrlFactory('clients') + '/api';

export async function GetAppSignUpsToday() {
  const URL = `${BASE_URL}/Clients/sign-up-count`;
  const response = await fetch(URL, { method: 'GET' });

  return await response.json();
}

export async function SubscriptionClientCountersAsync(subscriptionId) {
  const URL = `${BASE_URL}/statistics/clients/counter/${subscriptionId}`;
  const response = await fetch(URL, { method: 'GET' });

  return await response.json();
}

export async function SubscribersClientStatisticsAsync() {
  const URL = `${BASE_URL}/Statistics`;
  const response = await fetch(URL, { method: 'GET' });

  return await response.json();
}

export async function ClientsCountAsync() {
  const URL = `${BASE_URL}/Statistics/clients/count`;
  const response = await fetch(URL, { method: 'GET' });

  return await response.json();
}

export async function SessionsCountAsync() {
  const URL = `${BASE_URL}/Statistics/sessions/count`;
  const response = await fetch(URL, { method: 'GET' });

  return await response.json();
}

export async function apiMatchBackReport(
  { page = 1, ...queryParams },
  options = {},
) {
  const params = new URLSearchParams({ currentPage: page, ...queryParams });
  const URL = `${BASE_URL}/match-back-report/by-date?${params.toString()}`;

  const response = await fetch(URL, { method: `GET`, ...options });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error?.message, { cause: error });
  }

  return await response.json();
}

export async function apiMatchBackLocationReport(
  { page = 1, ...queryParams },
  options = {},
) {
  const params = new URLSearchParams({ currentPage: page, ...queryParams });
  const URL = `${BASE_URL}/match-back-report/location/by-date?${params.toString()}`;

  const response = await fetch(URL, { method: `GET`, ...options });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error?.message, { cause: error });
  }

  return await response.json();
}

export async function apiMatachBackReportSnapShots() {
  const URL = `${BASE_URL}/match-back-report/snapshots`;
  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function apiMatchBackReportExport(queryParams, options = {}) {
  const params = new URLSearchParams(queryParams);
  const URL = `${BASE_URL}/match-back-report/by-date/export?${params.toString()}}`;

  const response = await fetch(URL, { method: `GET`, ...options });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error?.message, { cause: error });
  }

  return await response.json();
}

export async function appointmentWebHookValidation(locationId, options) {
  const url = `${BASE_URL}/crm/appointment-authentication/?locationId=${encodeURIComponent(
    locationId,
  )}`;

  const response = await fetch(url, {
    method: `GET`,
    ...options,
  });

  return await response.json();
}

export async function appointmentWebHookTestValidation(locationId, options) {
  const url = `${BASE_URL}/crm/appointment-authentication/?locationId=${encodeURIComponent(
    locationId,
  )}`;

  const response = await fetch(url, {
    method: `POST`,
    ...options,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error?.message, { cause: error });
  }

  return await response.json();
}

export async function apiMatchBackLocationReportExport(
  queryParams,
  options = {},
) {
  const params = new URLSearchParams(queryParams);
  const URL = `${BASE_URL}/match-back-report/location/by-date/export?${params.toString()}`;

  const response = await fetch(URL, { method: `GET`, ...options });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error?.message, { cause: error });
  }

  return await response.json();
}
