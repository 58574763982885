import React, { useEffect, useState } from 'react';

import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';

import ErrorModal from '../../components/modals/ErrorModal';
import {
  apiHelloGymPostGatewaySettings,
  apiHelloGymPutGatewaySettings,
  apiHelloGymStripeKey,
} from '../../providers/Subscription/order-service';
import { apiGetBillingSettings } from '../../providers/Subscription/subscription.service';

export const PaymentSettings = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [gatewayToken, setGatewayToken] = useState(null);

  const [subscriptionId, setSubscriptionId] = useState(null);
  const [locationId, setLocationId] = useState(null);

  const fetchSettings = async () => {
    const { subscriptionId, locationId } = await apiGetBillingSettings();

    setSubscriptionId(subscriptionId);
    setLocationId(locationId);
  };

  const getDefaultGateway = async () => {
    const gateways = await apiHelloGymStripeKey(subscriptionId, locationId);

    const defaultGateway = gateways.find((x) => x.default);

    if (!defaultGateway) {
      return;
    }

    setApiKey(defaultGateway.keys.secret_key);
    setGatewayToken(defaultGateway.token);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    if (!subscriptionId) return;

    if (!locationId) return;

    getDefaultGateway();
  }, [subscriptionId, locationId]);

  const newGatewaySettings = () => !gatewayToken;

  async function postOrPut() {
    if (newGatewaySettings()) {
      await apiHelloGymPostGatewaySettings(subscriptionId, locationId, apiKey);
      return;
    }

    await apiHelloGymPutGatewaySettings(subscriptionId, locationId, apiKey);
  }

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);

      await postOrPut();
      await fetchSettings();
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="animated">
      <Card>
        <CardHeader>Update Hello Gym Payment Gateway Settings</CardHeader>
        <CardBody>
          <Form onSubmit={(e) => onSubmit(e)}>
            <FormGroup>
              <Label htmlFor="gateway">Gateway</Label>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-credit-card"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="text" name="gateway" value="Stripe" />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="authentication">Authentication Mode</Label>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-lock"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="text" name="authentication" value="Web API" />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="key">API Key</Label>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-key"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="key"
                  required
                  value={apiKey}
                  onChange={(e) => setApiKey(e.currentTarget.value)}
                />
                <FormFeedback>Please enter a valid API Key.</FormFeedback>
              </InputGroup>
            </FormGroup>

            <Row>
              <Col xs="6">
                <LaddaButton
                  type="submit"
                  className="btn btn-primary btn-ladda"
                  loading={loading}
                  data-color="green"
                  data-style={EXPAND_LEFT}
                >
                  <i className="fa fa-save"></i>
                  &nbsp; Update Settings
                </LaddaButton>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {error && (
        <ErrorModal message={error} callbackModal={() => setError(null)} />
      )}
    </div>
  );
};
