/**
 * VimCorPro Identity API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: felipegouveiae@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The VimCorProIdentityApiModelsPasswordResetLinkResponse model module.
* @module model/VimCorProIdentityApiModelsPasswordResetLinkResponse
* @version v1
*/
export default class VimCorProIdentityApiModelsPasswordResetLinkResponse {
    /**
    * Constructs a new <code>VimCorProIdentityApiModelsPasswordResetLinkResponse</code>.
    * @alias module:model/VimCorProIdentityApiModelsPasswordResetLinkResponse
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>VimCorProIdentityApiModelsPasswordResetLinkResponse</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/VimCorProIdentityApiModelsPasswordResetLinkResponse} obj Optional instance to populate.
    * @return {module:model/VimCorProIdentityApiModelsPasswordResetLinkResponse} The populated <code>VimCorProIdentityApiModelsPasswordResetLinkResponse</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VimCorProIdentityApiModelsPasswordResetLinkResponse();
                        
            
            if (data.hasOwnProperty('webResetLink')) {
                obj['webResetLink'] = ApiClient.convertToType(data['webResetLink'], 'String');
            }
            if (data.hasOwnProperty('androidResetLink')) {
                obj['androidResetLink'] = ApiClient.convertToType(data['androidResetLink'], 'String');
            }
            if (data.hasOwnProperty('iosResetLink')) {
                obj['iosResetLink'] = ApiClient.convertToType(data['iosResetLink'], 'String');
            }
            if (data.hasOwnProperty('rawResetToken')) {
                obj['rawResetToken'] = ApiClient.convertToType(data['rawResetToken'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} webResetLink
    */
    'webResetLink' = undefined;
    /**
    * @member {String} androidResetLink
    */
    'androidResetLink' = undefined;
    /**
    * @member {String} iosResetLink
    */
    'iosResetLink' = undefined;
    /**
    * @member {String} rawResetToken
    */
    'rawResetToken' = undefined;




}
