import React, { Component } from 'react';

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { Container } from 'reactstrap';

// sidebar nav config
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import navigation from '../../_nav';
// routes config
import { registerInterceptors } from '../../providers/api-config';
import routes, { routePathsInV6 } from '../../routes';

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.redirectUrl = '/login';
    this.state = {
      loading: true,
      navbar: '',
      routes: null,
    };

    registerInterceptors(this.props.history, this.redirectUrl);

    console.log(props);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));

    let navbar = navigation;
    let routesData = routes;

    this.checkPermissionsUser(navbar, user, routesData);

    return this.setState({
      navbar,
      routes: routesData,
      loading: !this.state.loading,
    });
  }

  checkPermissionsUser(navbar, user, routesData) {
    if (
      user &&
      user.roles &&
      user.roles.some((role) => {
        return role === 'SysAdmin';
      })
    ) {
      return;
    }

    if (
      user &&
      user.roles &&
      user.roles.some((role) => {
        return role === 'Personnel';
      })
    ) {
      const content = navigation.items.filter(
        (item) => item.role === 'personnel',
      );

      const contentRoutes = routesData.filter(
        (item) => item.role === 'personnel',
      );

      navbar = { items: content };
      routesData = contentRoutes;
    }
  }

  render() {
    return (
      <div className="app">
        {!this.state.loading && (
          <div>
            <AppHeader fixed>
              <DefaultHeader />
            </AppHeader>
            <div className="app-body">
              <AppSidebar fixed display="lg">
                <AppSidebarHeader />
                <AppSidebarForm />
                <AppSidebarNav navConfig={this.state.navbar} {...this.props} />
                <AppSidebarFooter />
                <AppSidebarMinimizer />
              </AppSidebar>
              <main className="main">
                <AppBreadcrumb appRoutes={this.state.routes} />
                <Container fluid>
                  <Switch>
                    {this.state.routes.map((route, idx) => {
                      if (routePathsInV6.includes(route.path)) {
                        return (
                          <CompatRoute
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                          />
                        );
                      }

                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => <route.component {...props} />}
                        />
                      ) : null;
                    })}
                    <Redirect from="/" to={this.redirectUrl} />
                  </Switch>
                </Container>
              </main>
              {/* <AppAside fixed hidden>
                <DefaultAside />
              </AppAside> */}
            </div>
            <AppFooter>
              <DefaultFooter />
            </AppFooter>
          </div>
        )}
      </div>
    );
  }
}

export default DefaultLayout;
