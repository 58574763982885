import {EndpointUrlFactory} from '../api-config.js';

const BASE_URL = EndpointUrlFactory('subscription');

export async function GetLocation() {
  const URL = `${BASE_URL}/api/Locations`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}
