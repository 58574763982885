import { EndpointUrlFactory } from '../api-config.js';

const BASE_URL = EndpointUrlFactory('pushnotification') + '/api';

export async function SendAll(model) {
    const URL = `${BASE_URL}/subscription/`;
    return await fetch(URL, {
        method: 'POST',
        body: JSON.stringify(model)
    });
}
