/**
 * VimCorPro Identity API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: felipegouveiae@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MicrosoftAspNetCoreIdentityIdentityRole from './MicrosoftAspNetCoreIdentityIdentityRole';
import VimCorProIdentityApiDataApplicationUser from './VimCorProIdentityApiDataApplicationUser';
import VimCorProIdentityApiDataCompany from './VimCorProIdentityApiDataCompany';

/**
* The VimCorProIdentityApiDataUserCompanyLocation model module.
* @module model/VimCorProIdentityApiDataUserCompanyLocation
* @version v1
*/
export default class VimCorProIdentityApiDataUserCompanyLocation {
    /**
    * Constructs a new <code>VimCorProIdentityApiDataUserCompanyLocation</code>.
    * @alias module:model/VimCorProIdentityApiDataUserCompanyLocation
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>VimCorProIdentityApiDataUserCompanyLocation</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/VimCorProIdentityApiDataUserCompanyLocation} obj Optional instance to populate.
    * @return {module:model/VimCorProIdentityApiDataUserCompanyLocation} The populated <code>VimCorProIdentityApiDataUserCompanyLocation</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VimCorProIdentityApiDataUserCompanyLocation();
                        
            
            if (data.hasOwnProperty('user')) {
                obj['user'] = VimCorProIdentityApiDataApplicationUser.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = MicrosoftAspNetCoreIdentityIdentityRole.constructFromObject(data['role']);
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = VimCorProIdentityApiDataCompany.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'String');
            }
            if (data.hasOwnProperty('roleId')) {
                obj['roleId'] = ApiClient.convertToType(data['roleId'], 'String');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {module:model/VimCorProIdentityApiDataApplicationUser} user
    */
    'user' = undefined;
    /**
    * @member {module:model/MicrosoftAspNetCoreIdentityIdentityRole} role
    */
    'role' = undefined;
    /**
    * @member {module:model/VimCorProIdentityApiDataCompany} company
    */
    'company' = undefined;
    /**
    * @member {String} locationId
    */
    'locationId' = undefined;
    /**
    * @member {String} userId
    */
    'userId' = undefined;
    /**
    * @member {String} roleId
    */
    'roleId' = undefined;
    /**
    * @member {String} companyId
    */
    'companyId' = undefined;




}
