import React, { Component } from 'react';

import {
  faCog,
  faShareSquare,
  faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BootstrapTable,
  TableHeaderColumn,
  DeleteButton,
} from 'react-bootstrap-table';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { NewDatetimeCellFormatter } from '../../components/index';
import { LoadingSquares } from '../../components/ui/Loading/Squares';

class SiteConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: false,
    };

    this.options = {
      sortIndicator: true,
      hideSizePerPage: true,
      paginationSize: 3,
      hidePageListOnlyOnePage: true,
      clearSearch: true,
      alwaysShowAllBtns: false,
      withFirstAndLast: false,
      sizePerPage: 50,
      deleteBtn: this.customDeleteButton,
      onDeleteRow: this.handleDeleteRow,
    };
  }

  componentDidMount() {
    this.setState({
      data: [
        {
          id: 1,
          name: 'HelloGym',
          description: 'AF Central TZ',
          modificationTime: new Date(),
        },
        {
          id: 2,
          name: 'HelloGym',
          description: 'AF Central TZ',
          modificationTime: new Date(),
        },
        {
          id: 3,
          name: 'HelloGym',
          description: 'AF Central TZ',
          modificationTime: new Date(),
        },
      ],
      loading: !this.state.loading,
    });
  }

  operationButtons = () => {
    return (
      <div className="flex table-buttons">
        <button onClick={this.handleSettings}>
          <FontAwesomeIcon icon={faCog} />
        </button>
        <button onClick={this.handleShare}>
          <FontAwesomeIcon icon={faShareSquare} />
        </button>
        <button onClick={this.handleOptions}>
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
      </div>
    );
  };

  customDeleteButton = (onClick) => {
    return (
      <DeleteButton
        btnText="Delete"
        className="table-btn-delete"
        btnGlyphicon=""
        onClick={onClick}
      />
    );
  };

  handleAddTemplate = () => {};

  handleDeleteRow = () => {};
  handleSettings = () => {};
  handleShare = () => {};
  handleOptions = () => {};

  render() {
    return (
      <div className="device-info-container">
        <Card>
          <CardHeader>
            <div className="flex page-header">
              <h1>Site Configuration</h1>
              <button
                onClick={this.handleAddTemplate}
                className="primary-btn justify-end"
              >
                + Add Template
              </button>
            </div>
          </CardHeader>

          <CardBody>
            {this.state.loading && <LoadingSquares />}

            {!this.state.loading && (
              <div>
                <BootstrapTable
                  data={this.state.data}
                  selectRow={{ mode: 'checkbox' }}
                  version="4"
                  striped
                  hover
                  pagination
                  search
                  deleteRow
                  options={this.options}
                >
                  <TableHeaderColumn isKey dataField="id" hidden>
                    Id
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="name">
                    Site Name
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="description">
                    Description
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="modificationTime"
                    dataFormat={NewDatetimeCellFormatter}
                  >
                    Modification Time
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="operationButtons"
                    dataAlign="center"
                    dataFormat={this.operationButtons}
                  >
                    Operations
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default SiteConfiguration;
