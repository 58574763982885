import {EndpointUrlFactory} from '../api-config.js';

const BASE_URL = EndpointUrlFactory('orders');

export async function GetAllOrdersToday() {
  const URL = `${BASE_URL}/api/Orders/all-today`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function GetAllOrdersPaidToday() {
  const URL = `${BASE_URL}/api/Orders/all-paid-today`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

/**
 * Returns a overall status of the subscriber.
 * @param {*} id
 */
export async function SubscriptionPaymentStatsAsync(id) {
  const URL = `${BASE_URL}/api/subscription-billing/stats/${id}`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

/**
 * Locates all orders of the subscriber
 * @param {guid - subscriber id} id
 */
export async function GetOrdersForBuyerAsync(id) {
  const URL = `${BASE_URL}/api/subscription-billing/subscriber/${id}`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function CancelOrderAsync(id) {
  const URL = `${BASE_URL}/api/subscription-billing/${id}`;
  const response = await fetch(URL, {method: 'DELETE'});

  return await response.json();
}

export async function ChargeOrderAsync(subscriptionId, id) {
  const URL = `${BASE_URL}/api/subscription-billing/charge/${subscriptionId}/${id}`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function SendPaymentLink(subscriptionId, id) {
  const URL = `${BASE_URL}/api/subscription-billing/send-order-payment-link/${id}`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function SubscriptionsWithPaymentMethodCountAsync() {
  const URL = `${BASE_URL}/api/payment-settings/payment-processing-supported`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function OrderStatisticsAsync() {
  const URL = `${BASE_URL}/api/orders/stats/`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}
