import {EndpointUrlFactory} from '../api-config.js';

const BASE_URL = EndpointUrlFactory('orders');

export async function apiHelloGymStripeKey(subscriptionId, locationId) {
  const params = new URLSearchParams({subscriptionId, locationId});
  const response = await fetch(`${BASE_URL}/api/payment-settings/hello-gym?${params.toString()}`, {
    method: `GET`
  });
  return await response.json();
}

export async function apiHelloGymPostGatewaySettings(subscriptionId, locationId, key) {
  const request = {
    gatewayName: "Stripe",
    gatewayType: "Stripe",
    authenticationMode: "Stripe",
    additionalFields: {"secret_key": key},
    subscriptionId,
    locationId,
  };

  return await fetch(`${BASE_URL}/api/payment-settings/hello-gym`, {
    method: `POST`,
    body: JSON.stringify(request),
  });
}

export async function apiHelloGymPutGatewaySettings(subscriptionId, locationId, key) {
  const request = {
    gatewayName: "Stripe",
    gatewayType: "Stripe",
    authenticationMode: "Stripe",
    additionalFields: {"secret_key": key},
    subscriptionId,
    locationId,
  };
  return await fetch(`${BASE_URL}/api/payment-settings/hello-gym`, {
    method: `PUT`,
    body: JSON.stringify(request),
  });
}
