import React from 'react';

const PhoneFormatMask = (props) => {
  if (!props.cell) {
    return <span></span>;
  }

  return (
    <span>
      {props.cell
        ? '(' +
          props.cell.substring(0, 3) +
          ') ' +
          props.cell.substring(3, 6) +
          '-' +
          props.cell.substring(6)
        : '-'}
    </span>
  );
};

export { PhoneFormatMask };
