import 'spinkit/css/spinkit.css';

const LoadingSquares = () => (
  <div className="sk-wandering-cubes">
    <div className="sk-cube sk-cube1"></div>
    <div className="sk-cube sk-cube2"></div>
  </div>
);

export { LoadingSquares };
