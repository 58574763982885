import React, { Component } from 'react';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

class CustomDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

  render() {
    return (
      <Dropdown
        className="custom-dropdown"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle caret>{this.props.title}</DropdownToggle>
        <DropdownMenu>
          {this.props.items.map((elem, index) => (
            <DropdownItem key={index} onClick={elem.handler}>
              {elem.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export { CustomDropDown };
