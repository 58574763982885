import React, { Component } from 'react';

export class BooleanTextParser extends Component {
  render() {
    const value = this.props.cell || this.props.value;
    return (
      <span>
        {value && value.toString().toLowerCase() === 'true' ? 'Yes' : 'No'}
      </span>
    );
  }
}
