import { Component } from 'react';

import moment from 'moment';

class DateTimeMask extends Component {
  render() {
    return moment(this.props.cell).format('MM/DD/YYYY hh:mm:ss a');
  }
}

export { DateTimeMask };
