import fetchIntercept from "fetch-intercept";

function convertToEnvironmentServiceName(service) {
  return {
    'clients': process.env.REACT_APP_URL_CLIENTS,
    'identity': process.env.REACT_APP_URL_IDENTITY,
    'subscription': process.env.REACT_APP_URL_SUBSCRIPTION,
    'humanresources': process.env.REACT_APP_URL_HUMAN_RESOURCES,
    'pushnotification': process.env.REACT_APP_URL_PUSH_NOTIFICATION,
    'orders': process.env.REACT_APP_URL_ORDER,
  }[service];
}

export function EndpointUrlFactory(service) {
  return convertToEnvironmentServiceName(service);
}

function getBearerToken() {
  return localStorage.getItem('token');
}

function isPublicUrl(url) {
  return url.includes('send-password-backoffice-reset-link') || url.includes('login-backoffice');
}

export function registerJwtTokenInterceptor() {
  fetchIntercept.register({
    request: function (url, config) {

      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      }

      if (!isPublicUrl(url) && getBearerToken()) {
        config.headers = {
          ...config.headers,
          'Authorization': `Bearer ${getBearerToken()}`,
        };
      }
      return [url, config];
    },
  });
}

function registerUnauthorizedInterceptor(router, defaultUrl) {
  fetchIntercept.register({
    response: function (responseData) {
      if (!responseData.ok && responseData.status === 401) {
        router.push(defaultUrl);
      }
      return responseData;
    },
  });
}

export function registerInterceptors(router, defaultUrl) {
  registerJwtTokenInterceptor();
  registerUnauthorizedInterceptor(router, defaultUrl);
}

