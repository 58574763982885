import { EndpointUrlFactory } from '../api-config.js';

const BASE_URL = EndpointUrlFactory('humanresources') + '/api/personnel';

export async function GetProfileDetails() {
  const url = BASE_URL + `/profile-details/`;
  const raw = await fetch(url, { method: 'GET' });

  if (!raw.ok) throw new Error();

  return await raw.json();
}
