import {EndpointUrlFactory} from '../api-config.js';

const BASE_URL = EndpointUrlFactory('identity') + '/api/user-management';

export async function GetAllUserSysAdmin() {
  const URL = `${BASE_URL}/all?role=SysAdmin`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function GetAllClients() {
  const URL = `${BASE_URL}/dashboard-backoffice`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function LastLoginAsync(subscriptionId) {
  const URL = `${BASE_URL}/last-login/company/${subscriptionId}`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function ClientFacingAppInstallsAsync() {
  const URL = `${BASE_URL}/stats/client/login`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}
