/**
 * VimCorPro Identity API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: felipegouveiae@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import VimCorProIdentityApiDataCompanyStatues from './VimCorProIdentityApiDataCompanyStatues';
import VimCorProIdentityApiDataUserCompanyLocation from './VimCorProIdentityApiDataUserCompanyLocation';

/**
* The VimCorProIdentityApiDataCompany model module.
* @module model/VimCorProIdentityApiDataCompany
* @version v1
*/
export default class VimCorProIdentityApiDataCompany {
    /**
    * Constructs a new <code>VimCorProIdentityApiDataCompany</code>.
    * @alias module:model/VimCorProIdentityApiDataCompany
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>VimCorProIdentityApiDataCompany</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/VimCorProIdentityApiDataCompany} obj Optional instance to populate.
    * @return {module:model/VimCorProIdentityApiDataCompany} The populated <code>VimCorProIdentityApiDataCompany</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VimCorProIdentityApiDataCompany();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('emailFrom')) {
                obj['emailFrom'] = ApiClient.convertToType(data['emailFrom'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('contactEmail')) {
                obj['contactEmail'] = ApiClient.convertToType(data['contactEmail'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = VimCorProIdentityApiDataCompanyStatues.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('userCompanyLocations')) {
                obj['userCompanyLocations'] = ApiClient.convertToType(data['userCompanyLocations'], [VimCorProIdentityApiDataUserCompanyLocation]);
            }
        }
        return obj;
    }

    /**
    * @member {String} id
    */
    'id' = undefined;
    /**
    * @member {String} emailFrom
    */
    'emailFrom' = undefined;
    /**
    * @member {String} name
    */
    'name' = undefined;
    /**
    * @member {String} contactEmail
    */
    'contactEmail' = undefined;
    /**
    * @member {module:model/VimCorProIdentityApiDataCompanyStatues} status
    */
    'status' = undefined;
    /**
    * @member {Array.<module:model/VimCorProIdentityApiDataUserCompanyLocation>} userCompanyLocations
    */
    'userCompanyLocations' = undefined;




}
