import React, { Component } from 'react';

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';

import DefaultHeaderDropdown from './DefaultHeaderDropdown';
import hgLogo from '../../assets/hg/hello-gym.png';
import hgLogoMin from '../../assets/hg/logo-squared.png';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: hgLogo, width: 120, height: 25, alt: 'Backoffice Logo' }}
          minimized={{
            src: hgLogoMin,
            width: 40,
            height: 30,
            alt: 'Backoffice Logo',
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>
          <DefaultHeaderDropdown accnt />
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
