import {EndpointUrlFactory} from '../api-config.js';

const BASE_URL = EndpointUrlFactory('subscription') + '/api/backoffice';

export async function GetDashboardStats() {
  const URL = `${BASE_URL}/dashboard`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function GetSubscriptionStatisticsAsync() {
  const URL = `${BASE_URL}/statistics`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}
