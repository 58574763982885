import React, { Component } from 'react';

import { createHashHistory } from 'history';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import logo from '../../../assets/hg/logo-squared.png';
import ErrorModal from '../../../components/modals/ErrorModal';
import { PostLoginBackofficeApi } from '../../../providers';
import UserApi from '../../../providers/swagger-cli-generated/identity/src/api/UserApi';

import 'ladda/dist/ladda-themeless.min.css';
import '../../../scss/style.css';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      loading: false,
      forgotPassword: false,
    };
  }

  login(event) {
    event.preventDefault();
    let pathToRedirect = '/subscription/list/';

    const model = {
      username: this.email.value,
      password: this.password.value,
    };

    const updatedState = {
      loading: true,
    };

    this.setState(updatedState);
    PostLoginBackofficeApi(model).then(
      (response) => {
        const updatedLoadingState = {
          loading: false,
        };

        if (!response.succeeded) {
          this.setState(updatedLoadingState);
          this.message = response.message;
          this.handleModal();
          return;
        }
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', JSON.stringify(response));

        if (
          response.roles.some((role) => {
            return role === 'Personnel';
          }) &&
          !response.roles.some((role) => {
            return role === 'SysAdmin';
          })
        ) {
          pathToRedirect = '/reports/call-history/';
        }

        this.setState(updatedLoadingState);

        createHashHistory().push(pathToRedirect);
      },
      (error) => {
        const updatedLoadingState = {
          loading: false,
        };

        this.setState(updatedLoadingState);
        this.message = 'Server Error';
        this.handleModal();
      },
    );
  }

  handleModal() {
    const updatedState = {
      active: true,
    };
    this.setState(updatedState);
  }

  callbackModal() {
    const updatedState = {
      active: false,
    };

    this.setState(updatedState);
  }

  async SendResetPassword(event) {
    event.preventDefault();
    let userService = new UserApi();
    let opts = {
      email: this.email.value,
    };
    await userService.apiUserSendPasswordBackofficeResetLinkGet(opts);
    this.setState({ forgotPassword: !this.state.forgotPassword });
    toast.info(
      'An e-mail has been sent, follow the instructions to reset your password.',
    );
  }

  render() {
    return (
      <div className="app flex-row align-items-center flex-column custom-login">
        <img width="150px;" alt="logo" src={logo} />
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              {!this.state.forgotPassword && (
                <Card className="p-4">
                  <CardBody>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    {/*  */}
                    <Form onSubmit={this.login.bind(this)}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            innerRef={(input) => (this.email = input)}
                            type="email"
                            placeholder="E-mail"
                            required
                          />
                          <FormFeedback>
                            Please enter a valid e-mail.
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            innerRef={(input) => (this.password = input)}
                            type="password"
                            placeholder="Password"
                            required
                            pattern=".{4,}"
                          />
                          <FormFeedback>
                            Please enter a valid password.
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      <Row>
                        <Col xs="8">
                          <LaddaButton
                            type="submit"
                            className="btn btn-primary btn-ladda"
                            loading={this.state.loading}
                            data-color="green"
                            data-style={EXPAND_LEFT}
                          >
                            Login
                          </LaddaButton>
                        </Col>
                        <div className="col-4">
                          <a
                            className="forgot-password"
                            onClick={() =>
                              this.setState(
                                { forgotPassword: !this.state.forgotPassword },
                                console.log(this.state.forgotPassword),
                              )
                            }
                          >
                            Forgot password?
                          </a>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              )}
              {this.state.forgotPassword && (
                <Card>
                  <CardBody>
                    <h1>Forgot password</h1>
                    <p className="text-muted">Please inform your email</p>
                    {/*  */}
                    <Form>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-user"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            innerRef={(input) => (this.email = input)}
                            type="email"
                            placeholder="E-mail"
                            required
                          />
                          <FormFeedback>
                            Please enter a valid e-mail.
                          </FormFeedback>
                        </InputGroup>
                      </FormGroup>
                      <Row>
                        <Col>
                          <LaddaButton
                            type="submit"
                            className="btn btn-primary btn-ladda"
                            loading={this.state.loading}
                            data-color="green"
                            data-style={EXPAND_LEFT}
                            onClick={(e) => this.SendResetPassword(e)}
                          >
                            Send
                          </LaddaButton>
                          &nbsp;
                          <a
                            data-color="green"
                            data-style={EXPAND_LEFT}
                            className="btn btn-secondary btn-ladda"
                            onClick={() =>
                              this.setState({
                                forgotPassword: !this.state.forgotPassword,
                              })
                            }
                          >
                            Back
                          </a>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>

        {this.state.active ? (
          <ErrorModal
            message={this.message}
            callbackModal={this.callbackModal.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default LoginForm;
