const EmailCellFormatter = function (cell, row) {

  if (!cell) {
    return;
  }

  return `<a href="mailto:${cell.toLowerCase()}" target="_blank">${cell.toLowerCase()}</a>`;
};

export { EmailCellFormatter };
