import { EndpointUrlFactory } from '../api-config.js';

const BASE_URL = EndpointUrlFactory('subscription');
const BASE_URL_IDENTITY = EndpointUrlFactory('identity');

export async function UpdateSubscriptor(model) {
  const URL = `${BASE_URL}/api/subscription`;

  const response = await fetch(URL, {
    method: 'PUT',
    body: JSON.stringify(model),
  });

  return response;
}

export async function GetAllSubscriptor() {
  const URL = `${BASE_URL}/api/subscription/all`;
  const response = await fetch(URL, { method: 'GET' });

  return await response.json();
}

export async function GetAllTrialsSubscriptions() {
  const URL = `${BASE_URL}/api/trials`;
  const response = await fetch(URL, { method: 'GET' });

  return await response.json();
}

export async function GetSubscriberById(id) {
  const URL = `${BASE_URL}/api/subscription/id/${id}`;

  const response = await fetch(URL, {
    method: 'GET',
  });

  return await response.json();
}

export async function GetAllLocations() {
  const URL = `${BASE_URL}/api/locations/all`;

  const response = await fetch(URL, {
    method: 'GET',
  });
  return await response.json();
}

export async function GetLocationsFromSubscription(subscriptionId) {
  const URL = `${BASE_URL}/api/locations/all/?subscriptionId=${subscriptionId}`;
  const response = await fetch(URL, {
    method: 'GET',
  });
  return await response.json();
}

export async function GetDashboardInfo() {
  const URL = `${BASE_URL}/api/subscription/dashboard-backoffice`;

  const response = await fetch(URL, {
    method: 'GET',
  });

  return await response.json();
}

export async function GetAllLogs() {
  const URL = `${BASE_URL}/api/subscription/logs`;

  const response = await fetch(URL, {
    method: 'GET',
  });

  return await response.json();
}

export async function GetSubscribersBillingStatus() {
  const URL = `${BASE_URL}/api/billing/open`;

  const response = await fetch(URL, {
    method: 'GET',
  });

  return await response.json();
}

export function ExtendTrial(subscriberId, daysToExtend) {
  const URL = `${BASE_URL}/api/billing/extend-trial/${subscriberId}/${daysToExtend}`;

  return fetch(URL, {
    method: 'POST',
  });
}

export async function ApiFacilitybasicsPutAsync(
  facility,
  subscription,
  locationId,
) {
  const URL = `${BASE_URL}/api/facilitybasics/${subscription}/${locationId}`;
  const result = await fetch(URL, {
    body: JSON.stringify(facility),
    method: 'PUT',
  });
  return result;
}

export async function ApiPromotionsPostForAdminAsync(subscriptionId, model) {
  const URL = `${BASE_URL}/api/promotions/admin/${subscriptionId}`;
  await fetch(URL, {
    body: JSON.stringify(model),
    method: 'POST',
  });
}

export async function ApiOperationsExceptionPutAsync(
  operationsException,
  subscription,
  locationId,
) {
  const URL = `${BASE_URL}/api/operationsException/${subscription}/${locationId}`;
  return await fetch(URL, {
    body: JSON.stringify(operationsException),
    method: 'PUT',
  });
}

export async function ApiOperationsExceptionDeleteAsync(
  id,
  locationId,
  subscriptionId,
) {
  const URL = `${BASE_URL}/api/operationsException/?locationId=${locationId}&id=${id}&subscriptionId=${subscriptionId}`;
  return fetch(URL, {
    method: 'DELETE',
  });
}

export async function ApiOperationsExceptionGetAsync(
  locationId,
  subscriptionId,
) {
  const URL = `${BASE_URL}/api/operationsException/personnel?locationId=${locationId}&subscriptionId=${subscriptionId}`;
  const response = await fetch(URL, {
    method: 'GET',
  });

  return await response.json();
}

export async function ApiCreateLocationAsync(model) {
  const URL = `${BASE_URL}/api/locations/hello-gym`;
  const response = await fetch(URL, {
    body: JSON.stringify(model),
    method: 'POST',
  });
  return await response.json();
}

export async function ApiLocationSetModuleAsync(model) {
  const URL = `${BASE_URL}/api/locations/modules`;
  const response = await fetch(URL, {
    body: JSON.stringify(model),
    method: 'PUT',
  });
  return response;
}

export async function ApiLocationGetModuleAsync(subscriptionId, locationId) {
  const URL = `${BASE_URL}/api/locations/modules/?subscriptionId=${subscriptionId}&locationId=${locationId}`;
  const response = await fetch(URL, {
    method: 'GET',
  });
  return await response.json();
}

export async function ApiDeleteLocationAsync(subscriptionId, locationId) {
  const URL = `${BASE_URL}/api/locations/hello-gym/${subscriptionId}/${locationId}`;
  return fetch(URL, {
    method: 'DELETE',
  });
}

export async function ApiGymLeadPutAsync(gymlead, subscription, locationId) {
  const URL = `${BASE_URL}/api/gymlead/${subscription}/${locationId}`;
  const response = await fetch(URL, {
    body: JSON.stringify(gymlead),
    method: 'PUT',
  });
  return response;
}

export async function ApiFindUserByEmailAsync(email) {
  const URL = `${BASE_URL_IDENTITY}/api/user/email-exists/?email=${encodeURIComponent(
    email,
  )}`;
  const response = await fetch(URL, {
    method: 'GET',
  });
  return response;
}

export async function ApiCreateNewPersonnelAsync(model) {
  const URL = `${BASE_URL_IDENTITY}/api/user/create-personnel-user/`;
  const response = await fetch(URL, {
    method: 'POST',
    body: JSON.stringify(model),
  });
  return response;
}

export async function ApiUpsertUserAsync(model) {
  console.log(model);
  const URL = `${BASE_URL_IDENTITY}/api/user-management/upsert-user`;
  const response = await fetch(URL, {
    method: 'PATCH',
    body: JSON.stringify(model),
  });
  return response;
}

export async function ApiDeleteUserByEmail(email) {
  const URL = `${BASE_URL_IDENTITY}/api/user-management/delete-by-email/${encodeURIComponent(
    email,
  )}`;
  const response = await fetch(URL, {
    method: 'DELETE',
  });
  return response;
}

export async function ApiResetPasswordUser(body) {
  const URL = `${BASE_URL_IDENTITY}/api/user/reset-backoffice-password`;
  const response = await fetch(URL, {
    body: JSON.stringify(body),
    method: 'POST',
  });
  return response;
}

export async function ApiContactUpsertSingleContact(body) {
  const URL = `${BASE_URL}/api/contacts/upsert-single-contact`;
  const response = await fetch(URL, {
    body: JSON.stringify(body),
    method: 'PATCH',
  });
  return response;
}

export async function ApiContactAllFromSubscription(subscriptionId) {
  const URL = `${BASE_URL}/api/contacts/all?subscriptionId=${subscriptionId}`;
  const response = await fetch(URL, {
    method: 'get',
  });
  return await response.json();
}

export async function ApiContactDetailsFromSubscription(subscriptionId, email) {
  const URL = `${BASE_URL}/api/contacts/detail-contact?subscriptionId=${subscriptionId}&email=${encodeURIComponent(
    email,
  )}`;
  const response = await fetch(URL, {
    method: 'get',
  });
  return await response.json();
}

export async function ApiContactDeleteByEmail(email, subscriptionId) {
  const URL = `${BASE_URL}/api/contacts?email=${encodeURIComponent(
    email,
  )}&subscriptionId=${subscriptionId}`;
  const response = await fetch(URL, {
    method: 'delete',
  });
  return response;
}

export async function ApiUsersGetPersonnelUsers() {
  const URL = `${BASE_URL_IDENTITY}/api/user/get-personnel-users`;
  const response = await fetch(URL, {
    method: 'GET',
  });
  return await response.json();
}

export async function ApiBillingPutAsync(billing, subscription, locationId) {
  const URL = `${BASE_URL}/api/billing/${subscription}/${locationId}`;
  return await fetch(URL, {
    body: JSON.stringify(billing),
    method: 'PUT',
  });
}

export async function ApiMembershipPutAsync(
  membership,
  subscriptionId,
  locationId,
) {
  const URL = `${BASE_URL}/api/membershipinformation/${subscriptionId}/${locationId}`;
  return await fetch(URL, {
    body: JSON.stringify(membership),
    method: 'PUT',
  });
}

export async function ApiAmenitiesPutAsync(
  amenities,
  subscription,
  locationId,
) {
  const URL = `${BASE_URL}/api/amenities/${subscription}/${locationId}`;

  return await fetch(URL, {
    body: JSON.stringify(amenities),
    method: 'PUT',
  });
}

export async function ApiOperationPutAsync(
  operations,
  subscription,
  locationId,
) {
  const URL = `${BASE_URL}/api/operations/${subscription}/${locationId}`;
  return await fetch(URL, {
    body: JSON.stringify(operations),
    method: 'PUT',
  });
}

export async function ApiInsuranceDiscountsPutAsync(
  insuranceDiscounts,
  subscription,
  locationId,
) {
  const URL = `${BASE_URL}/api/insurancediscounts/${subscription}/${locationId}`;
  return await fetch(URL, {
    body: JSON.stringify(insuranceDiscounts),
    method: 'PUT',
  });
}

export async function ApiAgeTrialPassesPutAsync(
  ageTrialPasses,
  subscription,
  locationId,
) {
  const URL = `${BASE_URL}/api/ageTrialsPasses/${subscription}/${locationId}`;
  return await fetch(URL, {
    body: JSON.stringify(ageTrialPasses),
    method: 'PUT',
  });
}

export async function ApiCancelFreezePutAsync(
  cancelFreeze,
  subscription,
  locationId,
) {
  const URL = `${BASE_URL}/api/cancelFreeze/${subscription}/${locationId}`;
  return await fetch(URL, {
    body: JSON.stringify(cancelFreeze),
    method: 'PUT',
  });
}

export function apiMembershipinformationGetasyncLocationIdGet(id) {
  const URL = `${BASE_URL}/api/membershipinformation/getasync/${id}`;
  return fetch(URL, {
    method: 'GET',
  });
}

export function apiVoiceListGymleadPost(id) {
  const URL = `${BASE_URL}/api/voice/ListGymlead/${id}`;
  return fetch(URL, {
    method: 'POST',
  });
}

export function apiBillingGetAsync(id) {
  const URL = `${BASE_URL}/api/billing/getAsync/${id}`;
  return fetch(URL, {
    method: 'GET',
  });
}

export async function apiListTimeZoneAsync() {
  const URL = `${BASE_URL}/api/Company/timezone/list`;
  const response = await fetch(URL, {
    method: 'GET',
  });

  return await response.json();
}

export async function apiVoiceListCallHistory(
  page,
  numOfRegistries,
  id,
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  options,
) {
  const URL = `${BASE_URL}/api/call-history/sys-admin/by-date?currentPage=${page}&pageSize=${numOfRegistries}&helloGymId=${id}&search=${encodeURIComponent(
    search || '',
  )}&startDate=${encodeURIComponent(
    startDate,
  )}&endDate=${endDate}&orderBy=${encodeURIComponent(
    orderBy || '',
  )}&orderByDirection=${encodeURIComponent(orderByDirection)}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiCallHistoryByDateExport(
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  helloGymId,
  options,
) {
  const URL = `${BASE_URL}/api/call-history/sys-admin/by-date/export?search=${encodeURIComponent(
    search || '',
  )}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
    endDate,
  )}&orderBy=${encodeURIComponent(
    orderBy || '',
  )}&orderByDirection=${encodeURIComponent(
    orderByDirection,
  )}&helloGymId=${helloGymId}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiGymAnswersByDateExport(
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  helloGymId,
  options,
) {
  const URL = `${BASE_URL}/api/gymAnswer/sys-admin/by-date/export?search=${encodeURIComponent(
    search || '',
  )}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
    endDate,
  )}&orderBy=${encodeURIComponent(
    orderBy || '',
  )}&orderByDirection=${encodeURIComponent(
    orderByDirection,
  )}&helloGymId=${helloGymId}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiGymChatByDateExport(
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  helloGymId,
  options,
) {
  const URL = `${BASE_URL}/api/gymchat/sys-admin/by-date/export?search=${encodeURIComponent(
    search || '',
  )}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
    endDate,
  )}&orderBy=${encodeURIComponent(
    orderBy || '',
  )}&orderByDirection=${encodeURIComponent(
    orderByDirection,
  )}&helloGymId=${helloGymId}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiGymLeadByDateExport(
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  helloGymId,
  options,
) {
  const URL = `${BASE_URL}/api/gymlead/sys-admin/by-date/export?search=${search || ''
    }&startDate=${startDate}&endDate=${encodeURIComponent(
      endDate,
    )}&orderBy=${encodeURIComponent(
      orderBy || '',
    )}&orderByDirection=${encodeURIComponent(
      orderByDirection,
    )}&helloGymId=${helloGymId}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiGymLeadStatsByDateExport(
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  helloGymId,
  options,
) {
  const URL = `${BASE_URL}/api/gymlead-stats/sys-admin/by-date/export?search=${search || ''
    }&startDate=${startDate}&endDate=${encodeURIComponent(
      endDate,
    )}&orderBy=${encodeURIComponent(
      orderBy || '',
    )}&orderByDirection=${encodeURIComponent(
      orderByDirection,
    )}&helloGymId=${helloGymId}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiVoicemailByDateExport(
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  helloGymId,
  options,
) {
  const URL = `${BASE_URL}/api/voice-mail/sys-admin/by-date/export?search=${search || ''
    }&startDate=${startDate}&endDate=${encodeURIComponent(
      endDate,
    )}&orderBy=${encodeURIComponent(
      orderBy || '',
    )}&orderByDirection=${encodeURIComponent(
      orderByDirection,
    )}&helloGymId=${helloGymId}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiGymVidList(
  startDate,
  endDate,
  status,
  search,
  page,
  maxList,
  options,
) {
  console.log(
    'list: ',
    status + '; ' + startDate + '; ' + endDate + '; ' + search,
  );
  const URL = `${BASE_URL}/api/gym-vid/pending-approval?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
    endDate,
  )}&status=${status}&search=${search}&currentPage=${page}&pageSize=${maxList}`;
  const response = await fetch(URL, {
    method: 'GET',
    ...options,
  });
  return await response.json();
}

export async function apiGymVidListGetAll(model, options) {
  console.log(model);
  const URL = `${BASE_URL}/api/gym-vid/appointments/sys-admin/find/?locationId=${model.locationId}&search=${model.search}&start=${model.start}&end=${model.end}&currentPage=${model.currentPage}&pageSize=${model.pageSize}`;
  const response = await fetch(URL, {
    method: 'GET',
    ...options,
  });
  return await response.json();
}

export async function apiListStatusGymVid(options) {
  const URL = `${BASE_URL}/api/gym-vid/list-videos-status`;
  const response = await fetch(URL, {
    method: 'GET',
    ...options,
  });
  return await response.json();
}

export async function apiUpdateGymVid(model) {
  console.log(model);
  const URL = `${BASE_URL}/api/gym-vid/update-status`;
  const response = await fetch(URL, {
    method: 'PATCH',
    body: JSON.stringify(model),
  });
  return response;
}

export async function approveGymVid(model) {
  console.log(model);
  const URL = `${BASE_URL}/api/gym-vid/approve`;
  const response = await fetch(URL, {
    method: 'PATCH',
    body: JSON.stringify(model),
  });
  return response;
}

export async function denyGymVid(model) {
  console.log(model);
  const URL = `${BASE_URL}/api/gym-vid/deny`;
  const response = await fetch(URL, {
    method: 'PATCH',
    body: JSON.stringify(model),
  });
  return response;
}

export async function apiVoiceListVoiceMail(
  page,
  numOfRegistries,
  id,
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  subscriptionId,
  options,
) {
  const URL = `${BASE_URL}/api/voice-mail/sys-admin/by-date?currentPage=${page}&pageSize=${numOfRegistries}&helloGymId=${id}&subscriptionId=${subscriptionId}&search=${search || ''
    }&startDate=${startDate}&endDate=${encodeURIComponent(
      endDate,
    )}&orderBy=${orderBy}&orderByDirection=${encodeURIComponent(
      orderByDirection,
    )}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiVoiceListGymChat(
  page,
  numOfRegistries,
  id,
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  options,
) {
  const URL = `${BASE_URL}/api/gymchat/sys-admin/by-date?currentPage=${page}&pageSize=${numOfRegistries}&helloGymId=${id}&search=${search || ''
    }&startDate=${startDate}&endDate=${encodeURIComponent(
      endDate,
    )}&orderBy=${orderBy}&orderByDirection=${encodeURIComponent(
      orderByDirection,
    )}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiVoiceListGymAnswers(
  page,
  numOfRegistries,
  id,
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  options,
) {
  const URL = `${BASE_URL}/api/gymAnswer/sys-admin/by-date?currentPage=${page}&pageSize=${numOfRegistries}&helloGymId=${id}&search=${search || ''
    }&startDate=${startDate}&endDate=${encodeURIComponent(endDate)}&orderBy=${orderBy || ''
    }&orderByDirection=${encodeURIComponent(orderByDirection)}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiVoiceListGymLead(
  page,
  numOfRegistries,
  id,
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  options,
) {
  const URL = `${BASE_URL}/api/gymLead/sys-admin/by-date?currentPage=${page}&pageSize=${numOfRegistries}&helloGymId=${id}&search=${search || ''
    }&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(
      endDate,
    )}&orderBy=${orderBy}&orderByDirection=${encodeURIComponent(
      orderByDirection,
    )}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiMatachBackReport(
  page,
  numOfRegistries,
  id,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  membershipPrice,
  conversationRate,
) {
  const URL = `${BASE_URL}/api/match-back-report/by-date?currentPage=${page}&pageSize=${numOfRegistries}&conversationRate=${conversationRate}&defaultMembershipValue=${membershipPrice}&helloGymId=${id}&startDate=${startDate}&endDate=${encodeURIComponent(
    endDate,
  )}&orderBy=${orderBy}&orderByDirection=${encodeURIComponent(
    orderByDirection,
  )}`;
  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function apiMatchBackReportExport(
  page,
  numOfRegistries,
  id,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  membershipPrice,
  conversationRate,
) {
  const URL = `${BASE_URL}/api/match-back-report/by-date/export?currentPage=${page}&pageSize=${numOfRegistries}&conversationRate=${conversationRate}&defaultMembershipValue=${membershipPrice}&helloGymId=${id}&startDate=${startDate}&endDate=${encodeURIComponent(
    endDate,
  )}&orderBy=${orderBy}&orderByDirection=${encodeURIComponent(
    orderByDirection,
  )}`;
  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function apiMatchBackLocationReport(
  page,
  numOfRegistries,
  id,
  conversationRate,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  membershipPrice,
  options,
) {
  const URL = `${BASE_URL}/api/match-back-report/location/by-date?currentPage=${page}&pageSize=${numOfRegistries}&helloGymId=${id}&conversationRate=${conversationRate}&defaultMembershipValue=${membershipPrice}&startDate=${startDate}
  &endDate=${encodeURIComponent(
    endDate,
  )}&orderBy=${orderBy}&orderByDirection=${encodeURIComponent(
    orderByDirection,
  )}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiMatachBackLocationReportExport(
  page,
  numOfRegistries,
  id,
  conversationRate,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  membershipPrice,
) {
  const URL = `${BASE_URL}/api/match-back-report/location/by-date/export?currentPage=${page}&pageSize=${numOfRegistries}&helloGymId=${id}&conversationRate=${conversationRate}&defaultMembershipValue=${membershipPrice}&startDate=${startDate}
  &endDate=${encodeURIComponent(
    endDate,
  )}&orderBy=${orderBy}&orderByDirection=${encodeURIComponent(
    orderByDirection,
  )}`;
  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function apiGymLeadStatsSysAdmin(
  page,
  numOfRegistries,
  id,
  search,
  startDate,
  endDate,
  orderBy,
  orderByDirection,
  options,
) {
  const URL = `${BASE_URL}/api/gymlead-stats/sys-admin/by-date?currentPage=${page}&pageSize=${numOfRegistries}&helloGymId=${id}&search=${search || ''
    }&startDate=${startDate}&endDate=${encodeURIComponent(
      endDate,
    )}&orderBy=${orderBy}&orderByDirection=${encodeURIComponent(
      orderByDirection,
    )}`;
  const response = await fetch(URL, {
    method: `GET`,
    ...options,
  });
  return await response.json();
}

export async function apiGetLocationDetails(subscriptionId, locationId) {
  const URL = `${BASE_URL}/api/Locations/${subscriptionId}/${locationId}`;
  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function getLocationByHelloGymId(helloGymId) {
  const URL = `${BASE_URL}/api/locations/hello-gym/by-hello-gym-id?id=${helloGymId}`;
  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function MoveLocationToAnotherSubscription(
  targetSubscriptionId,
  locationId,
) {
  const URL = `${BASE_URL}/api/subscription/move-location`;

  const data = {
    targetSubscriptionId,
    locationId,
  };

  return fetch(URL, {
    body: JSON.stringify(data),
    method: 'POST',
  });
}

export function apiCreateSubscriptionPost(data) {
  const URL = `${BASE_URL}/api/company/`;
  return fetch(URL, {
    body: JSON.stringify(data),
    method: 'POST',
  });
}

export async function apiGetAccessPointHardwareTypes() {
  const URL = `${BASE_URL}/api/hardware/hardware-types/`;
  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function apiGetAccessPoints(subscriptionId, locationId) {
  const URL =
    `${BASE_URL}/api/hardware/access-points/` +
    `?subscriptionId=${encodeURIComponent(subscriptionId)}` +
    `&locationId=${encodeURIComponent(locationId)}`;

  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function apiGetAllAccessPoints() {
  const URL = `${BASE_URL}/api/hardware/access-points/all`;
  const response = await fetch(URL, {
    method: `GET`,
  });
  return await response.json();
}

export async function apiCreateAccessPoint(data) {
  const URL = `${BASE_URL}/api/hardware/access-points/`;
  return await fetch(URL, {
    body: JSON.stringify(data),
    method: 'POST',
  });
}

export async function apiLinkAccessPoint(data) {
  const URL = `${BASE_URL}/api/hardware/access-points/`;
  return await fetch(URL, {
    body: JSON.stringify(data),
    method: 'PATCH',
  });
}

export async function apiDeleteAccessPoints(
  subscriptionId,
  locationId,
  accessPointId,
) {
  const URL =
    `${BASE_URL}/api/hardware/access-points/` +
    `?subscriptionId=${encodeURIComponent(subscriptionId)}` +
    `&locationId=${encodeURIComponent(locationId)}` +
    `&accessPointId=${encodeURIComponent(accessPointId)}`;

  return await fetch(URL, {
    method: `DELETE`,
  });
}

export async function apiGetBillingSettings() {
  const response = await fetch(
    `${BASE_URL}/api/bounded-contexts/billing/billing-settings`,
    {
      method: `GET`,
    },
  );
  return await response.json();
}

export async function apiGetCountries() {
  const response = await fetch(`${BASE_URL}/api/globalization/countries`, {
    method: `GET`,
  });
  return await response.json();
}

export function alrAuthenticationUrl() {
  return `${BASE_URL}/api/integrations/active-lead-response/auth`;
}

export async function apiSendPushNotificationToOwner(data) {
  const URL = `${BASE_URL}/api/push-notification`;
  return await fetch(URL, {
    body: JSON.stringify(data),
    method: 'POST',
  });
}
export async function apiLocationsAlrIntegration(
  { page = 1, ...queryParams },
  options = {},
) {
  const params = new URLSearchParams({ currentPage: page, ...queryParams });
  const URL = `${BASE_URL}/api/locations/all-with-crm-module?${params.toString()}`;
  const response = await fetch(URL, { method: `GET`, ...options });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error?.message, { cause: error });
  }

  return await response.json();
}

export async function apiLocationStatusAuthAlr(locationId, options) {
  const URL = `${BASE_URL}/api/integrations/active-lead-response/auth-status/?locationId=${encodeURIComponent(locationId)}`;

  const response = await fetch(URL, { method: `GET`, ...options });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error?.message, { cause: error });
  }

  return await response.json();
}
