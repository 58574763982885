/**
 * VimCorPro Identity API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: felipegouveiae@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The VimCorProIdentityDomainInterfacesUsersIUserContact model module.
* @module model/VimCorProIdentityDomainInterfacesUsersIUserContact
* @version v1
*/
export default class VimCorProIdentityDomainInterfacesUsersIUserContact {
    /**
    * Constructs a new <code>VimCorProIdentityDomainInterfacesUsersIUserContact</code>.
    * @alias module:model/VimCorProIdentityDomainInterfacesUsersIUserContact
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>VimCorProIdentityDomainInterfacesUsersIUserContact</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/VimCorProIdentityDomainInterfacesUsersIUserContact} obj Optional instance to populate.
    * @return {module:model/VimCorProIdentityDomainInterfacesUsersIUserContact} The populated <code>VimCorProIdentityDomainInterfacesUsersIUserContact</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VimCorProIdentityDomainInterfacesUsersIUserContact();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'String');
            }
            if (data.hasOwnProperty('fullname')) {
                obj['fullname'] = ApiClient.convertToType(data['fullname'], 'String');
            }
            if (data.hasOwnProperty('firstname')) {
                obj['firstname'] = ApiClient.convertToType(data['firstname'], 'String');
            }
            if (data.hasOwnProperty('lastname')) {
                obj['lastname'] = ApiClient.convertToType(data['lastname'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {String} id
    */
    'id' = undefined;
    /**
    * @member {String} companyId
    */
    'companyId' = undefined;
    /**
    * @member {String} fullname
    */
    'fullname' = undefined;
    /**
    * @member {String} firstname
    */
    'firstname' = undefined;
    /**
    * @member {String} lastname
    */
    'lastname' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;




}
