import React, { Component } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

class ErrorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
    };
  }

  toggleDanger() {
    const updatedState = {
      active: false,
    };

    this.setState(updatedState);
    this.props.callbackModal();
  }

  render() {
    return (
      <Modal
        isOpen={this.state.active}
        toggle={this.toggleDanger.bind(this)}
        className={'modal-danger ' + this.props.className}
      >
        <ModalHeader toggle={this.toggleDanger.bind(this)}>Error</ModalHeader>
        <ModalBody>{this.props.message}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.toggleDanger.bind(this)}>
            Ok
          </Button>{' '}
        </ModalFooter>
      </Modal>
    );
  }
}

export default ErrorModal;
