/**
 * VimCorPro Identity API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: felipegouveiae@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import VimCorProIdentityApiDataCompany from './VimCorProIdentityApiDataCompany';

/**
* The VimCorProIdentityApiModelsUserDetails model module.
* @module model/VimCorProIdentityApiModelsUserDetails
* @version v1
*/
export default class VimCorProIdentityApiModelsUserDetails {
    /**
    * Constructs a new <code>VimCorProIdentityApiModelsUserDetails</code>.
    * @alias module:model/VimCorProIdentityApiModelsUserDetails
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>VimCorProIdentityApiModelsUserDetails</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/VimCorProIdentityApiModelsUserDetails} obj Optional instance to populate.
    * @return {module:model/VimCorProIdentityApiModelsUserDetails} The populated <code>VimCorProIdentityApiModelsUserDetails</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VimCorProIdentityApiModelsUserDetails();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('company')) {
                obj['company'] = VimCorProIdentityApiDataCompany.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('fullname')) {
                obj['fullname'] = ApiClient.convertToType(data['fullname'], 'String');
            }
            if (data.hasOwnProperty('firstname')) {
                obj['firstname'] = ApiClient.convertToType(data['firstname'], 'String');
            }
            if (data.hasOwnProperty('lastname')) {
                obj['lastname'] = ApiClient.convertToType(data['lastname'], 'String');
            }
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('roles')) {
                obj['roles'] = ApiClient.convertToType(data['roles'], ['String']);
            }
            if (data.hasOwnProperty('lastAccess')) {
                obj['lastAccess'] = ApiClient.convertToType(data['lastAccess'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
        }
        return obj;
    }

    /**
    * @member {String} id
    */
    'id' = undefined;
    /**
    * @member {module:model/VimCorProIdentityApiDataCompany} company
    */
    'company' = undefined;
    /**
    * @member {String} fullname
    */
    'fullname' = undefined;
    /**
    * @member {String} firstname
    */
    'firstname' = undefined;
    /**
    * @member {String} lastname
    */
    'lastname' = undefined;
    /**
    * @member {String} userName
    */
    'userName' = undefined;
    /**
    * @member {String} phoneNumber
    */
    'phoneNumber' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;
    /**
    * @member {Array.<String>} roles
    */
    'roles' = undefined;
    /**
    * @member {Date} lastAccess
    */
    'lastAccess' = undefined;
    /**
    * @member {Date} createdAt
    */
    'createdAt' = undefined;




}
