export default {
  items: [
    {
      name: 'Accounts',
      icon: 'fa fa-briefcase',
      url: '/subscription/list',
      role: 'sysadmin',
    },
    {
      name: 'Locations',
      icon: 'fa fa-map-pin',
      url: '/locations/list',
      role: 'sysadmin',
    },
    {
      name: 'Trials',
      icon: 'fa fa-clock-o',
      url: '/subscription/trials',
      role: 'sysadmin',
    },
    {
      name: 'CRM Integration',
      icon: 'fa fa-user',
      url: '/locations-alr/list',
      role: 'sysadmin',
    },
    {
      name: 'Match Back Report',
      url: '/reports/match-back',
      icon: 'fa fa-file ',
      role: 'sysadmin',
    },
    {
      name: 'Call History',
      url: '/reports/call-history',
      icon: 'fa fa-phone ',
      role: 'personnel',
    },
    {
      name: 'Voicemail',
      url: '/Reports/voice-mail',
      icon: 'fa fa-microphone ',
      role: 'personnel',
    },
    {
      name: 'GymChat',
      url: '/reports/gym-chat',
      icon: 'fa fa-wechat',
      role: 'personnel',
    },
    {
      name: 'GymLead',
      url: '/reports/gym-lead',
      icon: 'fa fa-flag',
      role: 'personnel',
    },
    {
      name: 'GymLead(Stats)',
      url: '/reports/gym-lead-stats',
      icon: 'fa fa-share-square',
      role: 'personnel',
    },
    {
      name: 'GymAnswering',
      url: '/reports/gym-answer',
      icon: 'fa fa-phone-square',
      role: 'personnel',
    },
    {
      name: 'Gym-Vid',
      url: '/reports/Gym-vid',
      icon: 'fa fa-file-video-o',
      role: 'personnel',
    },
    {
      name: 'Gym-Vid Pendding Approvals',
      url: '/Gym-vid/Gym-vid-details',
      icon: 'fa fa-video-camera',
      role: 'personnel',
    },
    {
      name: 'Hard Phone Management',
      icon: 'icon-wrench',
      children: [
        {
          name: 'Overview',
          url: '/phone-management/overview',
        },
        {
          name: 'Site Management',
          url: '/phone-management/site-management',
        },
        {
          name: 'SIP Device List',
          url: '/phone-management/sip-list',
        },
        {
          name: 'Firmware List',
          url: '/phone-management/firmware-management',
        },
        {
          name: 'Site Configuration',
          url: '/phone-management/site-configuration',
        },
      ],
      role: 'sysadmin',
    },
    {
      name: 'Push Notifications',
      url: '/mobile-app',
      icon: 'icon-screen-smartphone',
      role: 'sysadmin',
    },
    {
      name: 'Users',
      url: '/users/list',
      icon: 'fa fa-id-card-o',
      role: 'sysadmin',
    },
    {
      name: 'Payment Settings',
      url: '/payment-settings',
      icon: 'fa fa-credit-card',
      role: 'sysadmin',
    },
  ],
};
