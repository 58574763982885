import {EndpointUrlFactory} from '../api-config.js';

const BASE_URL = EndpointUrlFactory('subscription') + '/api/client-mobile';

export async function GetClientAppVersion() {
  const URL = `${BASE_URL}/version`;
  const response = await fetch(URL, {method: 'GET'});

  return await response.json();
}

export async function SetClientAppVersion(model) {
  const URL = `${BASE_URL}/version`;
  return await fetch(URL, {
    method: 'POST',
    body: JSON.stringify(model)
  });
}
