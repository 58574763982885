import React, { Component } from 'react';

import * as moment from 'moment';
import PropTypes from 'prop-types';

import { version } from '../../imports/version';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    const year = moment().year();

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>
          &copy; {year}{' '}
          <a target="blank" href="https://hellogym.io">
            HelloGym
          </a>
          .
        </span>
        <span className="ml-auto">{version} Version</span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
