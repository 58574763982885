import React, { Component } from 'react';

import { createHashHistory } from 'history';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import { GetProfileDetails } from '../../providers';

const propTypes = {
  accnt: PropTypes.bool,
};

const defaultProps = {
  accnt: false,
};

class DefaultHeaderDropdown extends Component {
  constructor(props) {
    super(props);

    this.onClickLogout = this.onClickLogout.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      user: {
        email: '',
        thumbnailUrl: '',
        firstName: '',
        lastName: '',
      },
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onClickLogout() {
    localStorage.setItem('token', '');
    createHashHistory().push('/login');
  }

  dropAccnt() {
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav style={{ marginRight: 1 + 'em' }}>
          {`${this.state.user.firstName} ${this.state.user.lastName} (${this.state.user.email})`}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={this.onClickLogout}>
            <i className="fa fa-lock"></i> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  async componentDidMount() {
    try {
      const profile = await GetProfileDetails();

      const { email, name, thumbnailUrl } = profile;
      const { firstName, lastName } = name;

      this.setState({
        user: {
          email,
          thumbnailUrl,
          firstName,
          lastName,
        },
      });
    } catch (error) {}
  }

  render() {
    const { accnt } = this.props;
    return accnt ? this.dropAccnt() : null;
  }
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default DefaultHeaderDropdown;
