/**
 * VimCorPro Identity API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * Contact: felipegouveiae@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import VimCorProDomainCoreEnumsUserStatus from './VimCorProDomainCoreEnumsUserStatus';
import VimCorProIdentityApiDataUserCompanyLocation from './VimCorProIdentityApiDataUserCompanyLocation';

/**
* The VimCorProIdentityApiDataApplicationUser model module.
* @module model/VimCorProIdentityApiDataApplicationUser
* @version v1
*/
export default class VimCorProIdentityApiDataApplicationUser {
    /**
    * Constructs a new <code>VimCorProIdentityApiDataApplicationUser</code>.
    * @alias module:model/VimCorProIdentityApiDataApplicationUser
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>VimCorProIdentityApiDataApplicationUser</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/VimCorProIdentityApiDataApplicationUser} obj Optional instance to populate.
    * @return {module:model/VimCorProIdentityApiDataApplicationUser} The populated <code>VimCorProIdentityApiDataApplicationUser</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new VimCorProIdentityApiDataApplicationUser();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
            if (data.hasOwnProperty('normalizedUserName')) {
                obj['normalizedUserName'] = ApiClient.convertToType(data['normalizedUserName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('normalizedEmail')) {
                obj['normalizedEmail'] = ApiClient.convertToType(data['normalizedEmail'], 'String');
            }
            if (data.hasOwnProperty('emailConfirmed')) {
                obj['emailConfirmed'] = ApiClient.convertToType(data['emailConfirmed'], 'Boolean');
            }
            if (data.hasOwnProperty('passwordHash')) {
                obj['passwordHash'] = ApiClient.convertToType(data['passwordHash'], 'String');
            }
            if (data.hasOwnProperty('securityStamp')) {
                obj['securityStamp'] = ApiClient.convertToType(data['securityStamp'], 'String');
            }
            if (data.hasOwnProperty('concurrencyStamp')) {
                obj['concurrencyStamp'] = ApiClient.convertToType(data['concurrencyStamp'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('phoneNumberConfirmed')) {
                obj['phoneNumberConfirmed'] = ApiClient.convertToType(data['phoneNumberConfirmed'], 'Boolean');
            }
            if (data.hasOwnProperty('twoFactorEnabled')) {
                obj['twoFactorEnabled'] = ApiClient.convertToType(data['twoFactorEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('lockoutEnd')) {
                obj['lockoutEnd'] = ApiClient.convertToType(data['lockoutEnd'], 'Date');
            }
            if (data.hasOwnProperty('lockoutEnabled')) {
                obj['lockoutEnabled'] = ApiClient.convertToType(data['lockoutEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('accessFailedCount')) {
                obj['accessFailedCount'] = ApiClient.convertToType(data['accessFailedCount'], 'Number');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'String');
            }
            if (data.hasOwnProperty('createdDate')) {
                obj['createdDate'] = ApiClient.convertToType(data['createdDate'], 'Date');
            }
            if (data.hasOwnProperty('fullname')) {
                obj['fullname'] = ApiClient.convertToType(data['fullname'], 'String');
            }
            if (data.hasOwnProperty('firstname')) {
                obj['firstname'] = ApiClient.convertToType(data['firstname'], 'String');
            }
            if (data.hasOwnProperty('lastname')) {
                obj['lastname'] = ApiClient.convertToType(data['lastname'], 'String');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'String');
            }
            if (data.hasOwnProperty('pinCodeHash')) {
                obj['pinCodeHash'] = ApiClient.convertToType(data['pinCodeHash'], 'String');
            }
            if (data.hasOwnProperty('voicemailPinCodeHash')) {
                obj['voicemailPinCodeHash'] = ApiClient.convertToType(data['voicemailPinCodeHash'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = VimCorProDomainCoreEnumsUserStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('isLocked')) {
                obj['isLocked'] = ApiClient.convertToType(data['isLocked'], 'Boolean');
            }
            if (data.hasOwnProperty('locations')) {
                obj['locations'] = ApiClient.convertToType(data['locations'], [VimCorProIdentityApiDataUserCompanyLocation]);
            }
        }
        return obj;
    }

    /**
    * @member {String} id
    */
    'id' = undefined;
    /**
    * @member {String} userName
    */
    'userName' = undefined;
    /**
    * @member {String} normalizedUserName
    */
    'normalizedUserName' = undefined;
    /**
    * @member {String} email
    */
    'email' = undefined;
    /**
    * @member {String} normalizedEmail
    */
    'normalizedEmail' = undefined;
    /**
    * @member {Boolean} emailConfirmed
    */
    'emailConfirmed' = undefined;
    /**
    * @member {String} passwordHash
    */
    'passwordHash' = undefined;
    /**
    * @member {String} securityStamp
    */
    'securityStamp' = undefined;
    /**
    * @member {String} concurrencyStamp
    */
    'concurrencyStamp' = undefined;
    /**
    * @member {String} phoneNumber
    */
    'phoneNumber' = undefined;
    /**
    * @member {Boolean} phoneNumberConfirmed
    */
    'phoneNumberConfirmed' = undefined;
    /**
    * @member {Boolean} twoFactorEnabled
    */
    'twoFactorEnabled' = undefined;
    /**
    * @member {Date} lockoutEnd
    */
    'lockoutEnd' = undefined;
    /**
    * @member {Boolean} lockoutEnabled
    */
    'lockoutEnabled' = undefined;
    /**
    * @member {Number} accessFailedCount
    */
    'accessFailedCount' = undefined;
    /**
    * @member {String} companyId
    */
    'companyId' = undefined;
    /**
    * @member {Date} createdDate
    */
    'createdDate' = undefined;
    /**
    * @member {String} fullname
    */
    'fullname' = undefined;
    /**
    * @member {String} firstname
    */
    'firstname' = undefined;
    /**
    * @member {String} lastname
    */
    'lastname' = undefined;
    /**
    * @member {String} position
    */
    'position' = undefined;
    /**
    * @member {String} pinCodeHash
    */
    'pinCodeHash' = undefined;
    /**
    * @member {String} voicemailPinCodeHash
    */
    'voicemailPinCodeHash' = undefined;
    /**
    * @member {module:model/VimCorProDomainCoreEnumsUserStatus} status
    */
    'status' = undefined;
    /**
    * @member {Boolean} isLocked
    */
    'isLocked' = undefined;
    /**
    * @member {Array.<module:model/VimCorProIdentityApiDataUserCompanyLocation>} locations
    */
    'locations' = undefined;




}
