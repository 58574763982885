import React, { Component } from 'react';

import { createHashHistory } from 'history';
import QueryString from 'qs';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormFeedback,
  FormGroup,
  Form,
} from 'reactstrap';

import logo from '../../../assets/hg/logo-squared.png';
import ErrorModal from '../../../components/modals/ErrorModal';
import { registerInterceptors } from '../../../providers/api-config';
import { ApiResetPasswordUser } from '../../../providers/Subscription/subscription.service';

import 'ladda/dist/ladda-themeless.min.css';
import '../../../scss/style.css';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    registerInterceptors(this.props.history, this.redirectUrl);

    this.state = {
      active: false,
      loading: false,
      forgotPassword: false,
    };
  }

  async saveNewPassword(event) {
    event.preventDefault();

    let pathToRedirect = '/login/';

    if (this.password.value !== this.passwordConfirmation.value) {
      toast.error("the passwords don't match!");
      return (this.passwordConfirmation.value = '');
    }

    let params = QueryString.parse(this.props.location.search);
    console.log(params);
    let opts = {
      passwordResetToken: this.props.match.params.passwordResetToken,
      email: this.props.match.params.email,
      newPassword: this.password.value,
    };
    await ApiResetPasswordUser(opts).then(async (result) => {
      if (result.ok) {
        return toast.success(
          'New password has been set! You going to be redirect',
        );
      }
      let data = await result.json();
      return toast.error(data);
    });
    setTimeout(() => {
      createHashHistory().push(pathToRedirect);
    }, 6000);
  }

  handleModal() {
    const updatedState = {
      active: true,
    };
    this.setState(updatedState);
  }

  callbackModal() {
    const updatedState = {
      active: false,
    };

    this.setState(updatedState);
  }

  render() {
    return (
      <div className="app flex-row align-items-center flex-column custom-login">
        <img width="150px" alt="logo" src={logo} />
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="p-4">
                <CardBody>
                  <h1>Reset Password</h1>
                  <p className="text-muted">
                    Fill the following field to reset your password.
                  </p>
                  {/*  */}
                  <Form onSubmit={this.saveNewPassword.bind(this)}>
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          innerRef={(input) => (this.password = input)}
                          type="password"
                          placeholder="Password"
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          innerRef={(input) =>
                            (this.passwordConfirmation = input)
                          }
                          type="password"
                          placeholder="Password Confirmation"
                          required
                          pattern=".{4,}"
                        />
                        <FormFeedback>
                          The password does not match.
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                    <Row>
                      <Col xs="8">
                        <LaddaButton
                          type="submit"
                          className="btn btn-primary btn-ladda"
                          loading={this.state.loading}
                          data-color="green"
                          data-style={EXPAND_LEFT}
                        >
                          Submit
                        </LaddaButton>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {this.state.active ? (
          <ErrorModal
            message={this.message}
            callbackModal={this.callbackModal.bind(this)}
          />
        ) : null}
      </div>
    );
  }
}

export default ResetPassword;
